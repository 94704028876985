import React from 'react';

const ThankYou = () => {
  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Confirmation</h1>
      </div>
      <div id="infoComp">
        <div id="confirmation">
          <p>
            Thank you for contacting us. We received your form and will get back
            to you soon.
          </p>
        </div>
      </div>
    </>
  );
};

ThankYou.propTypes = {};

export default ThankYou;
