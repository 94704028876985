import React from 'react';

import { PAGES } from '../../utils/utils';
import ThankYou from '../../components/en/thank-you';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const ThankYouPage = () => (
  <Layout lang="en" page={PAGES.MERCI}>
    <SEO title="Thank You!" lang="en" />
    <ThankYou />
  </Layout>
);

export default ThankYouPage;
